<template>
<nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
  <div class="inside-navigation grid-container">
    <div class="navigation-branding">
      <div class="site-logo">
        <router-link :to="{ name: `${selectedLanguage}-gb` }" title="GBWhatsApp" rel="home">
          <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
        </router-link>
      </div>
      <p class="main-title" itemprop="headline">
        <router-link :to="{ name: `${selectedLanguage}-gb` }" rel="home">
          GBWhatsApp
        </router-link>
      </p>
    </div>
    <ul id="menu-primary-menu" class="menu sf-menu mobile-menu-page" v-show="isMenuClicked">
      <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
        <router-link :to="{ name: `${selectedLanguage}-gb` }" exact>{{ this.translation[selectedLanguage].home }}</router-link>
      </li>
      <li id="menu-item-34 mods" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33 mods">
        <router-link :to="{ name: `${selectedLanguage}-mod` }">{{ this.translation[selectedLanguage].mod }}</router-link>
        <img src="@/assets/down.svg" alt="" @click="isModClicked=!isModClicked" v-if="!isModClicked">
        <img src="@/assets/up.svg" alt="" @click="isModClicked=!isModClicked" v-else>
      </li>
      <li class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33 mod-item"
          v-for="(data, i) in modsList" :key="i" v-show="isModClicked">
        <router-link :to="{ name: `en-${data.name}` }">{{ data.title }}</router-link>
      </li>
      <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
        <router-link :to="{ name: `${selectedLanguage}-download` }">{{ this.translation[selectedLanguage].download }}</router-link>
      </li>
      <li id="menu-item-34" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
        <router-link v-if="selectedLanguage === 'pt' || selectedLanguage === 'hi' || selectedLanguage === 'id'" :to="{ name: `${selectedLanguage}-blogs` }">{{ this.translation[selectedLanguage].blogs }}</router-link>
        <router-link v-else :to="{ name: `en-blogs` }">{{ this.translation[selectedLanguage].blogs }}</router-link>
      </li>
    </ul>

    <div id="primary-menu" class="main-nav">
      <div class="mobile-menu" v-if="$global.isMobileDevice()">
        <div class="language-selector">
          <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
            <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang" :label="data.val">
              {{ data.val }}
            </option>
          </select>
        </div>
        <div class="menu-toggle" @click="isMenuClicked=!isMenuClicked">
          <img src="@/assets/menu.svg" alt="menu button" v-if="!isMenuClicked">
          <img src="@/assets/xmark.svg" alt="close button" v-else>
        </div>
      </div>
      <ul v-else id="menu-primary-menu" class="menu sf-menu">
        <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
          <router-link :to="{ name: `${selectedLanguage}-gb` }" exact>{{ this.translation[selectedLanguage].home }}</router-link>
        </li>
        <li id="menu-item-34 mods" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33 mods"
            @mouseover="isHovered=true" @mouseleave="isHovered=false">
          <router-link :to="{ name: `${selectedLanguage}-mod` }">{{ this.translation[selectedLanguage].mod }}</router-link>
          <div class="mod-list" v-show="isHovered">
            <div class="mod-item" v-for="(data, i) in modsList" :key="i">
              <router-link :to="{ name: `${selectedLanguage}-${data.name}` }" class="mod-title" v-if="data.name=='plus' && (selectedLanguage=='ar'||selectedLanguage=='es')">{{ data.title }}</router-link>
              <router-link :to="{ name: `en-${data.name}` }" class="mod-title" v-else>{{ data.title }}</router-link>
            </div>
          </div>
        </li>
        <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <router-link :to="{ name: `${selectedLanguage}-download` }">{{ this.translation[selectedLanguage].download }}</router-link>
        </li>
        <li id="menu-item-34" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <router-link v-if="selectedLanguage === 'pt' || selectedLanguage === 'hi' || selectedLanguage === 'id'" :to="{ name: `${selectedLanguage}-blogs` }">{{ this.translation[selectedLanguage].blogs }}</router-link>
          <router-link v-else :to="{ name: `en-blogs` }">{{ this.translation[selectedLanguage].blogs }}</router-link>
        </li>

        <!-- 多语言 -->
        <li id="menu-item-35" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
          <div class="language-selector">
            <select id="language-select" v-model="selectedLanguage" placeholder="Select Language" @change="changeLanguage">
              <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                {{ data.val }}
              </option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  props: {
    selectedLanguage: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
      isMenuClicked: false,
      isModClicked: false,
      lang: [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
        {
          lang: 'pt',
          val: '🇵🇹 Português'
        },
        {
          lang: 'es',
          val: '🇪🇸 Español'
        },
        {
          lang: 'hi',
          val: '🇮🇳 हिंदी'
        },
      ],
      translation: {
        en: {
          home: 'Home',
          download: 'Download',
          blogs: 'Blogs',
          mod: 'WhatsApp Mods',
        },
        ar: {
          home: 'الصفحة الرئيسية',
          download: 'تحميل',
          blogs: 'المدونات',
          mod: 'مودات واتس اب',
        },
        id: {
          home: 'Beranda',
          download: 'Unduh',
          blogs: 'Blog',
          mod: 'Mod WhatsApp',
        },
        pt: {
          home: 'Casa',
          download: 'Baixar',
          blogs: 'Blogs',
          mod: 'Mods do WhatsApp',
        },
        es: {
          home: 'Casa',
          download: 'Descargar',
          blogs: 'Blogs',
          mod: 'Mods de WhatsApp',
        },
        hi: {
          home: 'घर',
          download: 'डाउनलोड',
          blogs: 'ब्लॉग',
          mod: 'व्हाट्सएप मोड्स',
        },
      },
      modsList: [
        {
            title: 'FM WhatsApp',
            name: 'fm'
        },
        {
            title: 'GBWhatsApp iOS',
            name: 'ios'
        },
        {
            title: 'YO WhatsApp',
            name: 'yo'
        },
        {
            title: 'WhatsApp Plus',
            name: 'plus'
        },
        {
            title: 'OB WhatsApp',
            name: 'ob'
        },
        {
            title: 'GB WhatsApp Pro',
            name: 'pro'
        }
      ]
    }
  },
  mounted() {
    if (this.page.includes('blog')) {
      this.lang = [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'pt',
          val: '🇵🇹 Português'
        },
        {
          lang: 'hi',
          val: '🇮🇳 हिंदी'
        },
        {
          lang: 'id',
          val: '🇮🇩 Bahasa Indonesia'
        },
      ];
    }
    if (this.page==='fm' || this.page==='ios' || this.page==='yo' || this.page==='ob' || this.page==='pro') {
      this.lang = [{
          lang: 'en',
          val: '🇺🇸 English'
        }
      ];
    }
    else if (this.page==='plus') {
      this.lang = [{
          lang: 'en',
          val: '🇺🇸 English'
        },
        {
          lang: 'ar',
          val: '🇦🇪 اَلْعَرَبِيَّةُ'
        },
        {
          lang: 'es',
          val: '🇪🇸 Español'
        },
      ];
    }
  },
  methods: {
    changeLanguage(lang) {
      console.log("changelang: ", lang);
      console.log("page: ", this.page);
      this.$router.push({
        name: `${this.selectedLanguage}-${this.page}`
      });
    },
    // goto(page) {
    //   if (page.includes('blog') && !(this.selectedLanguage === 'pt' || this.selectedLanguage === 'hi' || this.selectedLanguage === 'id')) {
    //     console.log('not pt or hi or id');
    //     this.$router.push({
    //       name: 'en-blogs'
    //     });
    //   } else if (page==='fm' || page==='ios' || page==='yo' || page==='plus' || page==='ob' || page==='pro') {
    //     this.$router.push({
    //       name: `en-${page}`
    //     });
    //   } else {
    //     this.$router.push({
    //       name: `${this.selectedLanguage}-${page}`
    //     });
    //   }
    // }
  },
}
</script>

<style lang="scss" scoped>
#backtop {
  width: 30px;
  height: 30px;
  position: fixed;
  right: 50px;
  bottom: 100px;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 50%;
  box-sizing: content-box;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.mods {
  position: relative;
  .mod-list{
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 1001;
    background: #212121;
    color: #ffffff;
    .mod-item {
      padding: 12px;
      border-bottom: 1px solid #ffffffac;
      font-size: 15px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #000000;
      }
      .mod-title {
        line-height: 22px;
        padding: 5px 0;
      }
    }
  }
}

.main-nav {
    .menu {
    list-style-type: none !important;
    margin: 0 !important;
  }
}

@media screen and (max-width: 768px) {

  .inside-navigation {
    position: relative;
    .mobile-menu-page {
      width: 100%;
      position: absolute;
      top:100%;
      z-index: 1001;
      margin: 0 !important;
      .menu-item {
        padding: 10px 0;
        margin-bottom: 0 !important;
        background: #323232;
        a {
          font-size: 15px;
          text-align: center;
          color: #ffffff;
          display: block;
        }
        &.mods {
          position: relative;
          img {
            width: 30px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            padding: 7px;
          }
        }
        &.mod-item {
          background: #434343;
          border: 0;
        }
      }
    }
  }
.main-nav {
  flex: 1;
  .mobile-menu{
    display: flex;
    justify-content: space-evenly;
    position: relative;
    .language-selector {
      width: 60%;
      #language-select {
        width: 100%;
      }
    }
    .menu-toggle {
      display: block;
      width: 25px;
      height: 25px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
}
</style>