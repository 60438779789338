<template>
      <div v-show="showBackTop" id="backtop" @click="scrollToTop()">
      <svg
        data-v-3c3e497c=""
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="currentColor"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        class="icon-top"
      >
        <path
          data-v-3c3e497c=""
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.00806 3.0625C4.70158 3.0625 4.45312 3.31095 4.45312 3.61743C4.45312 3.92391 4.70158 4.17236 5.00806 4.17236H14.9968C15.3033 4.17236 15.5518 3.92391 15.5518 3.61743C15.5518 3.31095 15.3033 3.0625 14.9968 3.0625H5.00806ZM10.3948 5.9997C10.1781 5.78297 9.82676 5.78297 9.61005 5.9997L6.07849 9.53126C5.86176 9.74797 5.86176 10.0993 6.07849 10.316C6.2952 10.5328 6.64656 10.5328 6.86327 10.316L9.44751 7.73182V17.3809C9.44751 17.6873 9.69596 17.9358 10.0024 17.9358C10.3089 17.9358 10.5574 17.6873 10.5574 17.3809V7.73182L13.1416 10.316C13.3583 10.5328 13.7097 10.5328 13.9264 10.316C14.1431 10.0993 14.1431 9.74797 13.9264 9.53126L10.3948 5.9997Z"
          stroke-width="0.5"
        />
      </svg>
    </div>
</template>

<script>
export default {
    data() {
    return {
      showBackTop: false,
    };
  },
  mounted() {
    if (window) {
      window.onscroll = function () {
        let currentHeight =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        if (currentHeight > 300) {
          this.showBackTop = true;
          document.getElementById("backtop").style.display = "block";
        } else {
          this.showBackTop = false;
          document.getElementById("backtop").style.display = "none";
        }
      };
    }
  },
  methods: {
    scrollToTop() {
      let topHeight =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let speed = topHeight / 10 > 100 ? topHeight / 10 : 100;
      scrollBy(0, -speed);
      // 模拟鼠标向上滚动事件
      this.scrolldelay = setTimeout(this.scrollToTop, 50);
      // 清除滚动事件，避免无法向下移动
      if (topHeight === 0) {
        clearTimeout(this.scrolldelay);
        this.scrolldelay = null;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#backtop {
	width: 30px;
	height: 30px;
	position: fixed;
	right: 50px;
	bottom: 100px;
	background: #f0f0f0;
	padding: 10px;
	border-radius: 50%;
	box-sizing: content-box;

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
}
</style>